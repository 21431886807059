const canvasSphere = document.getElementById('sphereCanvas');
const ctxSphere = canvasSphere.getContext('2d');

let sphereRadius;
let basePointSphereRadius = 1.5; // Radius of the point
const rotationSphereSpeed = -0.005; // Rotation speed
const tiltSphereAngle = Math.PI / 2; // 90 degrees tilt
let angleSphere = 0;

const pointsSphere = [];
const numSphereLatitudeLines = 30;
const numSphereLongitudeLines = 30;

function calculateSpherePoints() {
	pointsSphere.length = 0; // Clear existing points
	for (let i = 0; i <= numSphereLatitudeLines; i++) {
		const theta = i * Math.PI / numSphereLatitudeLines;
		const sinTheta = Math.sin(theta);
		const cosTheta = Math.cos(theta);

		for (let j = 0; j <= numSphereLongitudeLines; j++) {
			const phi = j * 2 * Math.PI / numSphereLongitudeLines;
			const x = sphereRadius * sinTheta * Math.cos(phi);
			const y = sphereRadius * cosTheta;
			const z = sphereRadius * sinTheta * Math.sin(phi);

			pointsSphere.push({ x, y, z });
		}
	}
}

function resizeSphereCanvas() {
	canvasSphere.width = window.innerWidth;
	canvasSphere.height = window.innerHeight;
	let maxRadiusWidth = canvasSphere.width * 0.6 / 2; // 60% of the screen width
	const maxRadiusHeight = canvasSphere.height * 0.9 / 2; // 80% of the screen height
	if ( window.matchMedia( '(min-width: 1200px)' ).matches ) {
		basePointSphereRadius = 1.5;
		maxRadiusWidth =  canvasSphere.width * 0.6 / 2;
	} else if ( window.matchMedia( '(min-width: 768px) and (max-width: 1199px)' ).matches ) {
		basePointSphereRadius = 1.5;
		maxRadiusWidth =  canvasSphere.width * 0.8 / 2;
	} else if ( window.matchMedia( '(max-width: 768px)' ).matches ) {
		basePointSphereRadius = 1;
		maxRadiusWidth =  canvasSphere.width * 0.9 / 2;
	}
	sphereRadius = Math.min(maxRadiusWidth, maxRadiusHeight); // Choose the smaller of the two
	calculateSpherePoints();
}

function drawSpherePoint(x, y, size) {
	ctxSphere.beginPath();
	ctxSphere.arc(x, y, size, 0, Math.PI * 2);
	ctxSphere.fill();
}

function renderSphere() {
	ctxSphere.clearRect(0, 0, canvasSphere.width, canvasSphere.height);
	ctxSphere.fillStyle = 'white';

	for (const point of pointsSphere) {
		// Apply tilt rotation around the X axis
		const tiltedY = point.y * Math.cos(tiltSphereAngle) - point.z * Math.sin(tiltSphereAngle);
		const tiltedZ = point.y * Math.sin(tiltSphereAngle) + point.z * Math.cos(tiltSphereAngle);

		// Rotate around the Y axis to position poles left and right
		const rotatedX = point.x * Math.cos(Math.PI / 2) - tiltedZ * Math.sin(Math.PI / 2);
		const rotatedZ = point.x * Math.sin(Math.PI / 2) + tiltedZ * Math.cos(Math.PI / 2);

		// Rotate around the X axis (horizontal axis) for rolling effect
		const rotatedY = tiltedY * Math.cos(angleSphere) - rotatedZ * Math.sin(angleSphere);
		const finalZ = tiltedY * Math.sin(angleSphere) + rotatedZ * Math.cos(angleSphere);

		const perspective = 900 / (900 + finalZ); // Perspective value
		const screenX = (rotatedX * perspective) + canvasSphere.width / 2;
		const screenY = (rotatedY * perspective) + canvasSphere.height / 2;

		if (screenX > 0 && screenX < canvasSphere.width) { // Ensure points don't go off screen horizontally
			const pointSize = basePointSphereRadius * perspective;
			drawSpherePoint(screenX, screenY, pointSize);
		}
	}

	angleSphere += rotationSphereSpeed;
	requestAnimationFrame(renderSphere);
}

window.addEventListener('resize', resizeSphereCanvas);
resizeSphereCanvas();
renderSphere();
